
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getRecepcionist, updateRecepcionist } from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import { UserInterface } from '@/interfaces/user.interface'
import { Validate } from 'vuelidate-property-decorators'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

@Component({
  components: {
    SellerHeader,
  },
})
export default class EditRecepcionist extends Vue {
  recepcionist: UserInterface = {}
  to: string = '/seller/recepcionists'

  @Validate({ minLength: minLength(8) }) password = ''
  @Validate({ sameAsPassword: sameAs('password') }) passwordRepeat = ''

  passwordRepeat = ''

  async submitEdit() {
    try {
      const body = {
        fullname: this.recepcionist.fullname,
        email: this.recepcionist.email,
        password: this.passwordRepeat,
        active: this.recepcionist.active
      };

      const response: any = await updateRecepcionist(this.recepcionist.id, body)

      if (response.status == 200) {
        Vue.$toast.success(`Recepcionista actualizado correctamente`)

        setTimeout(() => {
          this.$router.push({
            name: 'Compartir usuario',
            params: {
              fullname: this.recepcionist.fullname,
              email: this.recepcionist.email,
              username: this.recepcionist.email,
              password: this.passwordRepeat,
              title: 'Compartir Recepcionista',
              to: '/seller/administration/recepcionist-list'
            },
          })
        }, 500)
      } else {
        Vue.$toast.error('Ha ocurrido un error actualizando el recepcionista')
      }
    } catch (e) {
      console.error(e)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  mounted() {
    this.getRecepcionistData();

    if(this.$route.name == 'editar_recepcionista_admin') {
      this.to = '/seller/administration/recepcionist-list'
    }

  }

  async getRecepcionistData() {
    const data = await getRecepcionist(this.$route.params.id)
    this.recepcionist = data.data
  }

  /* INPUT VALIDATIONS */
  get passwordRepeatErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.passwordRepeat.$dirty) return errors
    !this.$v.passwordRepeat.sameAsPassword &&
      errors.push('Las contraseñas deben ser iguales')

    return errors
  }

  get passwordErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.password.$dirty) return errors
    !this.$v.password.minLength && errors.push('La contraseña no es válida')
    return errors
  }
}
